import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Footer from '@sections/Footer';

import { Section, Container } from '@components/global';

class PageTemplate extends Component {
    render() {
        const currentPage = this.props.data.wordpressPage

        return (
            <Layout>
                <Navbar />
                <Section id={currentPage.slug}>
                    <Container>
                        <h1 dangerouslySetInnerHTML={{ __html: currentPage.title }} />
                        <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />

                    </Container>
                </Section>
                <Footer />
             </Layout>
        )
    }
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      slug
    }
  }
`